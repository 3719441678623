<template>
    <user-dashboard-layout>
        <v-row>
            <v-col>
                <v-btn
                    color="primary"
                    class="mb-3"
                    @click="$router.push('/dashboard/templates')"
                >{{ $t('general.back') }}
                </v-btn>
            </v-col>
        </v-row>
        <v-card
            elevation="2"
            outlined>
            <form @submit.prevent="submit" v-if="template.subject">
                <v-row justify="center">
                    <v-col cols="6" class="py-0">
                        <v-card-text class="py-5">
                            <label for="type">{{$t('general.template-for')}}:</label>
                            <v-text-field
                                name="type"
                                type="text"
                                class="form-control"
                                v-model="template.type"
                                readonly
                            ></v-text-field>
                        </v-card-text>
                    </v-col>
                    <v-col cols="6" class="py-0">
                        <v-card-text class="py-5">
                            <label for="type">{{$t('general.email-subject')}}:</label>
                            <v-text-field
                                type="text"
                                class="form-control"
                                :placeholder="$t('general.enter-subject')"
                                v-model="template.subject"
                            ></v-text-field>
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-row justify="start">
                    <v-col class="py-0">
                        <v-card-text class="py-2">
                            <template-context v-if="context" :context="context"/>
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-row justify="start">
                    <v-col class="py-0">
                        <v-card-text class="py-5">
                            <ckeditor
                                height="300"
                                :editor="editor"
                                v-model="ckeditor_data"
                                @ready="onEditorReady"
                            ></ckeditor>
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-0">
                        <v-card-text class="py-5">
                            <v-btn
                                color="primary"
                                class="mb-3 mr-2"
                                @click="showPreview"
                            >{{$t('general.preview')}}
                            </v-btn>
                            <v-btn
                                type="submit"
                                color="success"
                                class="mb-3"
                            >{{$t('general.create')}}
                            </v-btn>
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-dialog v-model="previewDialog" width="50vw">
                    <v-card>
                        <v-card-title class="headline">{{$t('general.preview')}}</v-card-title>
                        <v-card-text v-html="previewHtml"></v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                text
                                @click="previewDialog = false"
                            >{{$t('general.close')}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </form>
        </v-card>
    </user-dashboard-layout>
</template>

<script>
import UserDashboardLayout from "../../../layouts/UserDashboardLayout";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import CKEditor from "@ckeditor/ckeditor5-vue2"
import {mapActions, mapGetters, mapMutations} from "vuex";
import TemplateContext from "../../../components/dashboard/TemplateContext.vue";
import {preview} from "../../../api/template";

export default {
    name: "dashboard.templates.edit",
    components: {TemplateContext, UserDashboardLayout, ckeditor: CKEditor.component},
    data: () => ({
        editor: ClassicEditor,
        item: false,
        dialog: false,
        ckeditor_data: '',
        previewHtml: '',
        previewDialog: false,
    }),
    computed: {
        ...mapGetters(["template", "templates", "context"]),
    },
    mounted() {
        this.init()
    },
    methods: {
        ...mapMutations(["setTemplate", "showSnackBar"]),
        ...mapActions(["storeTemplate", "updateTemplate", "fetchContextForTemplateType"]),
        async submit() {
            this.template.body = this.ckeditor_data
            this.setTemplate(this.template);
            this.dialog = true;
            await this.updateTemplate(this.template);
            await this.$router.push({name: 'dashboard.templates.index'})
        },
        async init() {
            this.template.type = this.$route.params.type;
            this.template.subject = this.$route.params.subject;
            this.template.body = this.$route.params.body;
            await this.fetchContextForTemplateType(this.template.type);
        },
        onEditorReady() {
            this.ckeditor_data = this.$route.params.body;
        },
        showPreview() {
            preview(this.template.type, this.template.body)
                .then(response => {
                    this.previewHtml = response.data.html;
                    this.previewDialog = true;
                })
                .catch(error => {
                    this.showSnackBar({
                        message: error.data.message,
                        color: 'error'
                    })
                });
        }
    },
    watch: {
        ckeditor_data(newData) {
            this.template.body = newData
        }
    }
}
</script>

<style>
.ck-editor__editable {
    min-height: 250px;
}
</style>
